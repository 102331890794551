import { InputComponent } from "@/components";
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
	name: "ResetPassword",
	components: {
		InputComponent,
	},
	setup() {
		const toast = useToast();

		return { toast };
	},
	data: () => ({
		form: {
			// email: "",
			password: "",
			repeatPassword: "",
			token: "",
		},
	}),
	methods: {
		...mapActions("account", {
			resetPassword: "RESET_PASSWORD",
		}),
		updatePassword() {
			// this.form.email --> removed because not necessary
			if (this.form.password && this.form.password === this.form.repeatPassword) {
				this.resetPassword(this.form).then(() => {
					this.$router.push({ name: "Login" });
				});
			} else {
				this.toast.error(`Passwords do not match.`);
			}
		},
	},
	created() {
		const token = this.$route.query.use_token;

		if (!token) {
			// If no token is present (in header), we push them back to the page to request a reset link.
			this.toast.error(`Error: No reset token provided`);
			this.$router.push({ name: "ForgotPassword" });
		}

		this.form.token = token;
	},
};
