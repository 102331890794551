<template>
	<div class="c-reset-password">
		<div class="o-container o-container--full">
			<div class="grid">
				<div class="row">
					<div class="col-md-6">
						<div class="c-reset-password__left">
							<div class="c-reset-password__switch">
								Didn’t forget your password?
								<router-link :to="{ name: 'Login' }" title="Create account"> Sign In </router-link>
							</div>
							<div class="c-reset-password__form">
								<h1>Reset password</h1>
								<p>Choose a new password.</p>
								<!-- <InputComponent
                  v-model="form.email"
                  input-type="email"
                  label="Email Address"
                  autocomplete="email"
                /> -->
								<InputComponent
									v-model="form.password"
									input-type="password"
									label="New Password"
									autocomplete="password"
								/>
								<InputComponent
									v-model="form.repeatPassword"
									input-type="password"
									label="Confirm New Password"
									autocomplete="password"
								/>
								<button class="c-button" @click="updatePassword()">Change Password</button>
							</div>
						</div>
					</div>
					<div class="col-md-6 u-tablet-hide">
						<div class="c-reset-password__right">
							<div class="c-reset-password__logo">
								<img src="~@/assets/images/logo-red.svg" alt="" />
							</div>
							<div class="c-reset-password__visual">
								<img src="~@/assets/images/login-visual.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./ResetPassword.controller.js"></script>
<style lang="scss" src="./ResetPassword.styles.scss"></style>
